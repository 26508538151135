import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import merge from 'deepmerge'
import { IBreadcrumbs, IGenericPageTemplateFields } from 'contentful-types'

import { Locale } from '../locale'
import Head from './Head/Head'
import Legal from './Legal'

import { pageAnalytics } from '../utils/analyticsObject'
import { adobePageAnalytics } from '../utils/adobeAnalyticsObject'

import { BlockRendererProps } from '../renderer/contentful/BlockRenderer'
import AvailableRegions from './AvailableRegions'
import { ValidExcludes } from '@/utils/handleExcludesQueryParam'
import type { AnalyticsScript } from '../lineOfBusiness/types'

import { useStackbit } from '@/hooks/useStackbit'
import useCampaignCode from '@/hooks/useCampaignCode'
import { ISiteBuilderEntry } from '@/siteBuilder/utils/types/ISiteBuilderEntryTypes'

const config = getConfig()?.publicRuntimeConfig

const BlockRenderer = dynamic<BlockRendererProps>(() =>
  import('../renderer/contentful/BlockRenderer').then((mod) => mod.BlockRenderer)
)
declare global {
  interface Window {
    dataLayer: any
    adobeDataLayer: any
  }
}

export type GenericPageProps = {
  genericPageTemplate: ISiteBuilderEntry<IGenericPageTemplateFields>
  locale: Locale
  excludes: Partial<Record<ValidExcludes, boolean>>
  analyticsScripts: AnalyticsScript[]
  brand?: 'telus'
}

const renderBreadcrumbs = (breadcrumbs: IBreadcrumbs) => <BlockRenderer block={breadcrumbs} fieldPath={'breadcrumbs'} />

export function GenericPage({ genericPageTemplate, locale }: GenericPageProps) {
  // Analytics
  // Old
  const { slug, breadcrumbs, seo, blocks, regions, structuredData, customTermsAndConditions, customFootnote } =
    genericPageTemplate.fields
  const { province, language } = locale
  const defaultDataLayerConfig = {
    page: {
      name: slug,
      province: province,
      language: language,
    },
    event: {},
    user: {},
  }

  const breadcrumbsComponent = breadcrumbs !== undefined && renderBreadcrumbs(breadcrumbs)

  // TODO: look into the possibility of creating a stackbit middleware for cookie checks
  const [stackbitProvCookie, setStackbitProvCookie] = useState('')

  useEffect(() => {
    // Initializing window.dataLayer - will eventually be deprecated
    const dataLayerConfig = pageAnalytics(genericPageTemplate.fields)
    window.dataLayer = merge(defaultDataLayerConfig, dataLayerConfig)

    // Initializing window.adobeDataLayer
    adobePageAnalytics(genericPageTemplate.fields, locale, incorrectRegion)

    // Get the region from the stackbitProv cookie or return undefined if the cookie doesn't exist
    const getStackbitProvCookie = (name: string) => {
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
      )
      return cookie ? decodeURIComponent(cookie[1]) : undefined
    }

    // Sets the region from the stackbitProv cookie or undefined if the cookie doesn't exist
    setStackbitProvCookie(getStackbitProvCookie('stackbitProv'))
  }, [])

  const incorrectRegion = regions && !regions.includes(province?.toLowerCase() as Lowercase<typeof province>)

  // This allows Stackbit to render the page when the Global, Canada English or French locales are used and
  // the Generic Page Template is set to render only for certain regions
  const incorrectRegionCheck =
    typeof stackbitProvCookie === 'string' ? incorrectRegion && stackbitProvCookie : incorrectRegion

  // Event listener for Stackbit locale changes
  useStackbit({ enabled: !config.IS_PROD })

  // CMP code
  useCampaignCode()

  return (
    <>
      <Head
        title={seo?.fields?.title}
        language={language}
        seo={seo?.fields}
        structuredData={structuredData}
        slug={slug}
      />
      <main id="app" data-sb-object-id={genericPageTemplate.sys.id}>
        {breadcrumbsComponent}
        {incorrectRegionCheck ? (
          <AvailableRegions provincesAllowed={regions} lang={language} slug={slug} />
        ) : (
          <BlockRenderer block={blocks} fieldPath={'blocks'} />
        )}
        <Legal customTermsAndConditions={customTermsAndConditions?.fields} customFootnote={customFootnote?.fields} />
      </main>
    </>
  )
}
